export const CONTENT_MENU: MenuItem[] = [
	{
		title: 'DASHBOARD.CONTENT.TITLE',
		icon: 'dashboard',
		route: '/dashboard'
	},
	{
		title: 'RETAIL_LOCATIONS.CONTENT.TITLE',
		icon: 'location_on',
		route: '/retail-locations'
	},
	{
		title: 'MASTER_DATA.CONTENT.TITLE',
		icon: 'trip_origin',
		route: '/master-data', 
		expectedPermission: "VIEW_MASTER_DATA"
	},
	{
		title: 'DATA_QUALITY.CONTENT.TITLE',
		icon: 'assessment',
		route: '/data-quality', 
		expectedPermission: "VIEW_DQM_REPORTS"
	},
	{
		title: 'ADMIN_DATA.CONTENT.TITLE',
		icon: 'people',
		route: '/admin-data', 
		expectedPermission: "USERS_MANAGEMENT"
	}, 
	{
		title: 'FILES.CONTENT.MENU_TITLE',
		icon: 'folder_open',
		route: '/files', 
		expectedPermission: "FILES_VIEW"
	}
];


interface MenuItem {
	title: string;
	icon?: string;
	route?: string;
	expectedPermission?: string
}
