import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/guards';
import { PrivateComponent } from './layouts/private/private.component';
import { PublicComponent } from './layouts/public/public.component';

const routes: Routes = [
	{
		path: 'login',
		component: PublicComponent,
		loadChildren: './modules/auth/auth.module#AuthModule'
	},
	{
		path: 'oauth',
		component: PublicComponent,
		loadChildren: './modules/oauth/oauth.module#OauthModule'
	},
	{
		path: '',
		component: PrivateComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
			},
			{
				path: 'retail-locations',
				loadChildren: './modules/retail-locations/retail-locations.module#RetailLocationsModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "SEARCH_LOCATION_PRIVILEGE"}
			},
			{
				path: 'master-data',
				loadChildren: './modules/master-data/master-data.module#MasterDataModule'
			},
			{
				path: 'data-quality',
				loadChildren: './modules/data-quality/data-quality.module#DataQualityModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "VIEW_DQM_REPORTS"}
			},
			{
				path: 'timetables/:id',
				loadChildren: './modules/timetables/timetables.module#TimetablesModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "VIEW_DQM_REPORTS"}
			},
			{
				path: 'tariffs/:id',
				loadChildren: './modules/tariffs/tariffs.module#TariffsModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "VIEW_DQM_REPORTS"}
			},
			{
				path: 'admin-data',
				loadChildren: './modules/admin-data/admin-data.module#AdminDataModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "USERS_MANAGEMENT"}
			},
			{
				path: 'files',
				loadChildren: './modules/files/files.module#FilesModule', 
				canActivate: [AuthGuard], data: {expectedPermission: "FILES_VIEW"}
			},
			{
				path: 'api-doc',
				loadChildren: './modules/swagger/swagger.module#SwaggerModule', 
			},
			{
				path: '**',
				redirectTo: '/dashboard',
				pathMatch: 'full'
			}
		]
  	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
