import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@app/core/services/session.service';
import { Router, NavigationEnd } from '@angular/router';
declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	
	constructor(private translateService: TranslateService, private sessionService: SessionService, public router: Router) {
		translateService.setDefaultLang(sessionService.getUserLanguage());
		translateService.use(sessionService.getUserLanguage());
		
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				ga('set', 'page', event.urlAfterRedirects);
				ga('send', 'pageview');
			}
		});
	}
	
	
}
